import { createApp } from 'vue';
import App from './App.vue';
import { createVuestic } from 'vuestic-ui';
import 'vuestic-ui/css';
import VueCookies from 'vue-cookies';
import Toaster from "@meforma/vue-toaster";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

const app=createApp(App);
app.use(createVuestic());
app.use(VueCookies);
app.use(LoadingPlugin);
app.use(Toaster,{position:"bottom",duration:4000});
app.mount('#app');

const disableForwardBackwardNavigation = () => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.forward(1);
  };
};

disableForwardBackwardNavigation();


