<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <div style="padding: 0 7rem;">
    <center class="mt-5" style="font-size: 2.5rem; color: white;"><b>Set-Top Box Information</b></center>
    <va-card style="width: 100%; height: 36rem;" class="mt-5">
      <div class="pt-5 pl-5" style="display: flex; justify-content: space-between;">
        <div class="mt-2">
          <span><b>Organization Name :</b></span>
          <span class="ml-2">{{ org_name }}</span>
        </div>
        <div class="pr-5">
          <va-input v-model="search" placeholder="Search Box" />
        </div>
      </div>
      <span v-if="loading" style="position: absolute; top: 18rem; left: 38rem; font-size: 16px; font-weight: bold;">
        Loading...
      </span>
      <div style="padding: 0 2.4rem 0 2.4rem;">
        <va-data-table class="mt-4" :items="contentList" :columns="columnList" wrapper-size="420" item-size="46"
          :per-page="totalCount" :striped="true" virtual-scroller sticky-header :empty-state-text="''"
          style="font-size: 85%; border: #ecf0f1 solid; border-radius: 10px;" />
      </div>
    </va-card>
  </div>
</template>

<script>
import { search } from '@/assets/searchComponent.js';
export default {
  name: 'App',
  data() {
    return {
      org_name: '',
      search: '',
      stbLists: [],
      totalCount: 0,
      org_id: '',
      loading: true
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const org_id = urlParams.get('org_id');
    const url = urlParams.get('pointing_url');
    const grid = urlParams.get('grid');
    const org_name = urlParams.get('org_name');
    this.org_name = org_name;
    
    if (grid) this.$cookies.set('grid', grid);
    this.getDetails(org_id, url);
    
    const windowUrl = new URL(window.location.href);
    windowUrl.search = '';
    window.history.replaceState(null, '', windowUrl.toString());
  },
  watch: {
    search() {
      search(this.search, this.stbLists);
    }
  },
  computed: {
    columnList() {
      var returnArray = [{ 'key': 'id' }];
      var grid = this.$cookies.get('grid');
      var dataArray = grid.split(',');
      var resultArray = dataArray.map((value) => { return value });
      resultArray.map((item, i) => {
        returnArray.push({ 'key': item });
      });
      return returnArray;
    },
    contentList() {
      return search(this.search, this.stbLists);
    }
  },
  methods: {
    getDetails(org_id, url) {
      this.socket = new WebSocket(`wss://${url}/websocket`);

      this.socket.onopen = () => {
        if (this.socket.readyState === this.socket.OPEN) {
          this.socket.send(JSON.stringify({ 'type': 'stb', 'org_id': org_id }));
        }
      };

      this.socket.onmessage = (event) => {
        this.stbLists = JSON.parse(event.data);
        this.loading = false;
        if (!this.stbLists.length) return this.$toast.error("No Active Box are presented in " + this.org_name);
        this.totalCount = this.stbLists.length;
        this.stbLists.map((it, index) => {
          it['id'] = index + 1 + '.';
          it['latency'] = (it['latency'] == 'null ms') ? '0 ms' : it['latency'];
          return it;
        });
      };

      this.socket.onclose = (closeMsg) => {
        this.$toast.error(closeMsg);
      };

      this.socket.onerror = (err) => {
        this.$toast.error(err);
      };
    }
  }
}
</script>

<style>
body {
  background: linear-gradient(to bottom, #4d4d4d 40%, #4d4d4d 40%);
  background-repeat: repeat-x !important;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 1em;
}

.va-data-table .va-data-table__table .va-data-table__table-tbody .no-data {
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: grid;
}
</style>
